<template>
  <div class="card-container">
    <div class="card-header">
      <p class="card-title">{{ title }}</p>
    </div>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style scoped>
.card-container {
  background-color: #ffffff;
  border: 1px solid #e5e7eb; 
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); 
  overflow: hidden;
}

.card-header {
  padding: 15px 24px;
  border-bottom: 1px solid #e5e7eb; 
}

.card-title {
  font-size: 18px; 
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.card-content {
  padding: 12px 24px 24px 24px; 
}
</style>
