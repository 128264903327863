<template>
  <div class="marketplace-container">
    <MarketplaceHeader :credits="budget"/>
    <div class="marketplace-body">
      <div v-if="user && user.id"
        class="sidebar-container"
        :class="{ 'sidebar--collapsed': isSidebarCollapsed }"
      >
        <MarketplaceSidebar
          :isSidebarCollapsed="isSidebarCollapsed"
          @toggleSidebar="toggleSidebar"
        />
      </div>
      <div class="content-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "../services/Services";
import MarketplaceHeader from "./MarketplaceHeader.vue";
import MarketplaceSidebar from "./MarketplaceSidebar.vue";
import { mapState } from "vuex";

export default {
  components: {
    MarketplaceHeader,
    MarketplaceSidebar,
  },
  data() {
    return {
      isSidebarCollapsed: false,
      budget: 0
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
  },
  async mounted(){
    const response = await Services.getStripeService().getCurrentBudget()
    this.budget = response.creditsInCent && response.creditsInCent / 100 || 0
  }
};
</script>

<style scoped>
@import "../scss/icons.scss";
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar (for horizontal scroll) */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the track */
    border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
    background: #b3b3b3; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 3px solid #f1f1f1; /* Space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3; /* Color when hovering over the scrollbar thumb */
}

.marketplace-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.marketplace-body {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 40px);
  flex-grow: 1;
  overflow: auto;
}

.sidebar-container {
  width: 250px;
  flex-shrink: 0;
  transition: width 0.2s ease-in-out;
}

.sidebar--collapsed {
  width: 60px;
}

.content-container {
  flex-grow: 1;
  overflow: auto;
}

@media (max-width: 768px) {
  .marketplace-body {
    flex-direction: column;
  }
  .sidebar-container {
    width: 100%;
  }
  .content-container {
    padding: 10px 0;
  }
}
</style>
