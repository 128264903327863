<template>
  <div>
      <div v-if="customer && !customer.address" class="header">
        <div>
          <p class="header-title">Billing</p>
          <p>Manage your billing and payment information</p>
        </div>
        <button
          variant="outline"
          @click="updateBillingAddress"
          class="MatcButton"
        >
          Update Billing Address
        </button>
      </div>

      <div>
        <!-- Status Messages -->
        <p v-if="statusMessage" class="status-message">
          {{ statusMessage }} 
        </p>
        <p v-if="errorMessage" class="error-message">
          {{ errorMessage }} 
        </p>
      </div>
      <div class="grid">
        <!-- Credits Card -->
        <div class="grid-column">
          <Card title="Credits" class="card">
            <div class="card-content">
              <div class="balance-box">
                <p>Current balance</p>
                <p v-if="loadingBalance" class="loading-spinner"></p>
                <p v-else class="balance-amount">
                  {{ currentBalance && currentBalance.toFixed(2) }} credits
                </p>
                <p class="balance-note">
                  Balance updates may take up to one hour to reflect recent
                  usage.
                </p>
              </div>
              <div class="credit-options">
                <div
                  v-for="option in creditOptions"
                  :key="option.value"
                  class="credit-option"
                  :class="{ selected: selectedCredit === option.value }"
                  @click="selectCredit(option.value)"
                >
                  <input
                    type="radio"
                    :id="'option-' + option.value"
                    :value="option.value"
                    v-model="selectedCredit"
                    class="credit-radio"
                  />
                  <label :for="'option-' + option.value" class="credit-label">
                    <span class="credit-amount">{{ option.label }}</span>
                    <span class="credit-description">{{
                      option.description
                    }}</span>
                  </label>
                </div>
                <div
                  v-if="selectedCredit === 'custom'"
                  class="custom-input-container"
                >
                  <input
                    type="number"
                    v-model.number="customValue"
                    class="custom-input"
                    placeholder="Enter custom value (min $5)"
                    min="5"
                    step="0.01"
                    @input="validateCustomValue"
                  />
                </div>
              </div>
              <!-- Button to initiate payment -->
              <button
                class="Matcbutton buy-credits-button"
                :disabled="isProcessing"
                @click="initiatePayment"
              >
                <span v-if="isProcessing" class="loading-spinner"></span>
                <span v-else>Buy {{ formatPrice() }}</span>
              </button>
            </div>
          </Card>
          <Card
            v-if="customer && customer.address"
            title="Billing Address"
            class="card"
          >
            <div class="card-content">
              <div class="address-box">
                <div class="address-info">
                  <p class="address-name">{{ customer.name }}</p>
                  <p class="address-line">{{ customer.address.line1 }}</p>
                  <p v-if="customer.address.line2" class="address-line">
                    {{ customer.address.line2 }}
                  </p>
                  <p class="address-line">
                    {{ customer.address.city }}, {{ customer.address.state }}
                    {{ customer.address.postal_code }}
                  </p>
                  <p class="address-line">{{ customer.address.country }}</p>
                </div>
                <button
                  class="MatcButton update-address-button"
                  :disabled="isProcessing"
                  @click="updateBillingAddress"
                >
                  Update Address
                </button>
              </div>
            </div>
          </Card>
        </div>

        <!-- Other Billing Cards -->
        <div class="grid-column">
          <!-- <Card title="Current Month Spend" class="card">
            <div class="card-content">
              <div class="spend-box">
                <p>Current month spend</p>
                <p v-if="loadingSpend" class="loading-spinner"></p>
                <p v-else class="spend-amount">
                  ${{ currentMonthSpend && currentMonthSpend.toFixed(2) }}
                </p>
                <div>
                  <Button
                    class="MatcButton"
                    variant="outline"
                    @click="viewCharges"
                    >View charges</Button
                  >
                </div>
              </div>
            </div>
          </Card>-->

          <Card title="Receipts" class="card">
            <div class="card-content">
              <div v-if="loadingReceipts" class="loading-spinner"></div>
              <DataTable
                v-else
                :columns="receiptColumns"
                :data="receipts"
                empty-message="You haven't made any payments yet."
              />
            </div>
          </Card>

          <Card title="Invoices" class="card">
            <div class="card-content">
              <div v-if="loadingInvoices" class="loading-spinner"></div>
              <DataTable
                v-else
                :columns="invoiceColumns"
                :data="invoices"
                empty-message="You haven't received any invoices yet."
              />
            </div>
          </Card>
        </div>
      </div>
    <!-- Stripe Payment Modal -->
    <div v-if="showPaymentModal" class="modal-overlay">
      <div class="modal-content">
        <form @submit.prevent="handlePayment">
          <div id="payment-element"></div>
          <div class="modal-buttons">
            <button class="MatcButton" @click="closePaymentModal">
              Cancel
            </button>
            <button class="MatcButton" type="submit" :disabled="isProcessing">
              <span v-if="isProcessing" class="loading-spinner"></span>
              <span v-else>Confirm Payment</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="showAddressModal" class="modal-overlay">
      <div class="modal-content">
        <form @submit.prevent="handleAddress">
          <div id="address-element"></div>
          <div class="modal-buttons">
            <button class="MatcButton" @click="closeAddressModal">
              Cancel
            </button>
            <button class="MatcButton" type="submit" :disabled="isProcessing">
              <span v-if="isProcessing" class="loading-spinner"></span>
              <span v-else>Save Address</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js/pure";
import Card from "./components/Card.vue";
import DataTable from "./components/DataTable.vue";
import Services from "../services/Services";

export default {
  components: {
    Card,
    DataTable,
  },
  props: ["user"],
  data() {
    return {
      currentBalance: 0,
      selectedCredit: 20,
      currentMonthSpend: 0.0,
      customValue: 0,
      creditOptions: [
        {
          value: 10,
          label: "$10",
        },
        {
          value: 20,
          label: "$20",
        },
        {
          value: 50,
          label: "$50",
        },
        {
          value: "custom",
          label: "Custom",
        },
      ],
      receiptColumns: [
        { key: "date", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "actions", label: "" },
      ],
      invoiceColumns: [
        { key: "date", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "actions", label: "" },
      ],
      receipts: [],
      invoices: [],
      stripe: null,
      elements: null,
      showStripePayment: false,
      clientSecret: "",
      statusMessage: "",
      errorMessage: "",
      isProcessing: false,
      showPaymentModal: false,
      customer: {},
      showAddressModal: false,
      addressElements: null,
      loadingBalance: false,
      loadingSpend: false,
      loadingReceipts: false,
      loadingInvoices: false,
    };
  },
  computed: {
    isValidAmount() {
      const amount = this.getSelectedAmount();
      return amount >= 5 && !isNaN(amount);
    },
  },
  methods: {
    formatPrice() {
      const amount = this.getSelectedAmount();
      return `$${parseFloat(amount).toFixed(2)}`;
    },
    selectCredit(value) {
      this.selectedCredit = value;
      this.errorMessage = "";
      if (value !== "custom") {
        this.customValue = 5;
      }
    },
    validateCustomValue() {
      if (this.customValue < 5) {
        this.errorMessage = "Minimum amount is $5";
      } else {
        this.errorMessage = "";
      }
    },
    getSelectedAmount() {
      return this.selectedCredit === "custom"
        ? this.customValue
        : this.selectedCredit;
    },
    async initiatePayment() {
      const amount = this.getSelectedAmount();
      if (!this.isValidAmount) {
        this.errorMessage = "Please enter a valid amount (minimum $5).";
        return;
      }
      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      try {
        const response = await Services.getStripeService().createPaymentIntent(
          this.user,
          amount
        );
        this.clientSecret = response.clientSecret;
        this.showPaymentModal = true;
        this.$nextTick(() => {
          this.setupPaymentElements();
        });
      } catch (error) {
        this.errorMessage = "Error creating payment intent.";
        console.error("Error creating payment intent:", error);
      } finally {
        this.isProcessing = false;
      }
    },
    closePaymentModal() {
      this.showPaymentModal = false;
      // Optionally, clean up Stripe elements if necessary
      if (this.elements) {
        this.elements = null;
      }
    },

    async updateBillingAddress() {
      this.showAddressModal = true;
      this.$nextTick(() => {
        this.setupAddressElements();
      });
    },
    setupPaymentElements() {
      if (!this.stripe || !this.clientSecret) {
        this.errorMessage = "Stripe setup error. Please try again.";
        return;
      }
      this.elements = this.stripe.elements({ clientSecret: this.clientSecret });
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
    },
    async handlePayment() {
      if (!this.stripe || !this.clientSecret) {
        this.errorMessage = "Payment setup error. Please try again.";
        return;
      }

      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Optionally, provide return_url if you want to redirect after payment
          // return_url: 'https://yourdomain.com/payment-success',
        },
        redirect: "if_required",
      });

      if (error) {
        this.errorMessage = error.message;
        console.error("Payment failed:", error.message);
        this.isProcessing = false;
      } else {
        // Delay to allow webhook processing
        setTimeout(this.updateBalance, 3000); // Wait for 3 seconds
      }
    },
    async updateBalance() {
      this.loadingBalance = true;
      try {
        const budget = await Services.getStripeService().getCurrentBudget();
        this.currentBalance =
          budget.creditsInCent > 0 ? budget.creditsInCent / 100 : 0;
        await this.fetchCustomerDetails();
        this.statusMessage = "Balance updated successfully.";
      } catch (error) {
        this.errorMessage = "Failed to update balance.";
        console.error("Error updating balance:", error);
      } finally {
        this.loadingBalance = false;
        this.closePaymentModal();
        this.isProcessing = false;
      }
    },
    viewCharges() {
      // Implement your method to display detailed charges
      alert("Feature not implemented yet.");
    },
    async fetchCustomerDetails() {
      this.loadingSpend = true;
      this.loadingReceipts = true;
      this.loadingInvoices = true;
      try {
        const response = await Services.getStripeService().getCustomerDetails(
          this.user.id
        );

        this.receipts = response.charges.map((charge) => ({
          date: new Date(charge.created * 1000).toLocaleDateString(),
          amount: `$${(charge.amount / 100).toFixed(2)}`,
          actions: {
            type: "link",
            url: charge.receipt_url,
            label: "View Receipt",
          },
        }));
        this.invoices = response.invoices.map((invoice) => ({
          date: new Date(invoice.created * 1000).toLocaleDateString(),
          amount: `$${(invoice.total / 100).toFixed(2)}`,
          actions: {
            type: "link",
            url: invoice.invoice_pdf,
            label: "Download PDF",
          },
        }));
        this.customer = response.customer;
        console.log(this.customer);
      } catch (error) {
        console.error("Error fetching current month spend:", error);
        this.errorMessage = "Failed to load billing details.";
      } finally {
        this.loadingSpend = false;
        this.loadingReceipts = false;
        this.loadingInvoices = false;
      }
    },
    async setupAddressElements() {
      if (!this.stripe) {
        this.errorMessage = "Stripe setup error. Please try again.";
        return;
      }

      this.addressElements = this.stripe.elements();
      const addressElement = this.addressElements.create("address", {
        mode: "billing",
        defaultValues: {
          name: this.customer.name,
          address: (this.customer && this.customer.address) || {},
        },
      });
      addressElement.mount("#address-element");
    },

    closeAddressModal() {
      this.showAddressModal = false;
      if (this.addressElements) {
        const addressElement = this.addressElements.getElement("address");
        if (addressElement) {
          addressElement.destroy();
        }
        this.addressElements = null;
      }
    },

    async handleAddress(event) {
      event.preventDefault();
      if (!this.stripe || !this.addressElements) {
        this.errorMessage = "Address setup error. Please try again.";
        return;
      }

      this.isProcessing = true;
      this.statusMessage = "";
      this.errorMessage = "";

      const addressElement = this.addressElements.getElement("address");
      const { complete, value: addressDetails } =
        await addressElement.getValue();

      if (complete) {
        try {
          // Update the address in Stripe
          await this.updateStripeAddress(addressDetails);
          this.statusMessage =
            "Billing address updated successfully in Stripe.";
          this.closeAddressModal();
          // Refresh customer details to reflect the new address
          await this.fetchCustomerDetails();
        } catch (error) {
          this.errorMessage =
            "Failed to update billing address. Please try again.";
          console.error("Error updating billing address in Stripe:", error);
        }
      } else {
        this.errorMessage = "Please fill in all required address fields.";
      }

      this.isProcessing = false;
    },
    async updateStripeAddress(addressDetails) {
      if (!this.user.stripeCustomerID) {
        throw new Error("Stripe Customer ID not found");
      }

      const addressData = {
        address: {
          line1: addressDetails.address.line1,
          line2: addressDetails.address.line2,
          city: addressDetails.address.city,
          state: addressDetails.address.state,
          postal_code: addressDetails.address.postal_code,
          country: addressDetails.address.country,
        },
        name: addressDetails.name,
        phone: addressDetails.phone,
      };

      // Call your backend API to update the address in Stripe
      await Services.getStripeService().updateBillingAddress(
        this.user.id,
        addressData
      );
    },
  },
  async mounted() {
    try {
      this.stripe = await loadStripe(
        "pk_test_51PoW6eIuXbKQu0JfFV1RQst8a2Q34c9ch7B3FgvQzkeo1V7joZhNqfYA78KCXF3DD2uBx121BslzWasbnrsHv6ou006tg7CTeM"
      );
      const budget = await Services.getStripeService().getCurrentBudget();
      this.currentBalance =
        budget.creditsInCent > 0 ? budget.creditsInCent / 100 : 0;
      await this.fetchCustomerDetails();
    } catch (error) {
      console.error("Error initializing Stripe:", error);
    }
  },
};
</script>

<style scoped>
@import "../scss/account-billing.scss";
</style>
