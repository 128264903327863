<template>
  <section class="">
    <div class="">
      <div class="breadcrumb enhanced-breadcrumb">
        <div class="">
          <a href="/">Home</a>
          <span class="mdi mdi-chevron-right"></span>
        </div>
        <div class="is-active">
          <a>{{ store.name }}</a>
        </div>
      </div>

      <div class="MatcCard enhanced-card">
        <div v-if="isLoading" class="loading-overlay">
          <div class="spinner"></div>
        </div>
        <div v-else class="MatcCardContent">
          <div class="MatcFlex settings-flex enhanced-flex">
            <div class="settings-cover enhanced-cover-card">
              <div class="cover-preview">
                <img
                  v-if="store.coverImage"
                  :src="store.coverImage"
                  alt="Cover Image"
                  class="cover-image-preview enhanced-cover-image"
                />
              </div>
            </div>

            <!-- App Data Section -->
            <div class="settings-data enhanced-data-section">
              <div class="MatcFlex MatcBetween MatcCenter">
                <div v-if="store.types" class="badges">
                  <span
                    v-for="(type, typeIndex) in store.types"
                    :key="typeIndex"
                    class="badge"
                  >
                    {{ typeLabelMap[type] || type }}
                  </span>
                </div>

                <div class="button-bar">
                  <a
                    class="button favs-button is-fullwidth"
                    @click="handleFavoriteToggle"
                  >
                    <span class="mdi mdi-star"></span>
                    {{ favoriteButtonText }}
                  </a>
                  <a
                    class="button is-primary is-fullwidth buy-button"
                    @click="handleTryNow"
                  >
                    Try Now
                  </a>
                </div>
              </div>
              <div class="form-group enhanced-form-group">
                <p class="title form-text">{{ store.name }}</p>
              </div>

              <div class="form-group enhanced-form-group">
                <p class="author form-text">{{ store.author }}</p>
              </div>

              <div class="form-group enhanced-form-group">
                <p class="desc form-text preserve-format">
                  {{ store.description }}
                </p>
              </div>
              <div class="pricinginfo form-group enhanced-form-group">
                <span class="badge"><span class="icon icon-tabler icon-tabler-coins"/>Price Info</span>
                <p class="form-text preserve-format">
                  {{ store.pricinginfo }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseDialog
      ref="loginDialog"
      title="Login"
      :labels="{ confirm: 'Login', cancel: 'Cancel', type: 'primary' }"
      @confirmAction="redirectToLogin"
    >
      Please Login to use this app
    </BaseDialog>
  </section>
</template>

<style lang="scss">
@import "../scss/marketplace.scss";
</style>

<script>
import Services from "../services/Services";
import ConstantsUtil from "../util/ConstantsUtil.js";
import BaseDialog from "./components/BaseDialog.vue";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      app: {},
      store: {},
      logos: {},
      typesOptions: [
        { value: "image_generation", label: "Image Generation" },
        { value: "agent", label: "Agent" },
        { value: "text_to_speech", label: "Text to Speech" },
        { value: "transcription", label: "Transcription" },
        { value: "multimodal", label: "Multimodal" },
        { value: "chat", label: "Chat" },
      ],
      isInFavorites: false,
      isLoading: true,
    };
  },
  components: {
    BaseDialog,
  },
  computed: {
    ...mapState(["user"]),
    typeLabelMap() {
      return this.typesOptions.reduce((map, typeOption) => {
        map[typeOption.value] = typeOption.label;
        return map;
      }, {});
    },
    formattedPrice() {
      return `${
        this.store.currency && this.store.currency.toUpperCase()
      } ${Number(this.store.price).toFixed(2)}`;
    },
    favoriteButtonText() {
      return this.isInFavorites ? "Remove from Favorites" : "Add to Favorites";
    },
  },
  methods: {
    ...mapActions(["loadUser", "updateUser"]),
    async handleFavoriteToggle() {
      if (!this.user || !this.user.id) {
        if (this.$refs && this.$refs.loginDialog) {
          this.$refs.loginDialog.show();
        }
      } else {
        if (this.isInFavorites) {
          await this.removeFromFavs();
        } else {
          await this.addToFavs();
        }
      }
    },
    handleTryNow() {
      if (!this.user || !this.user.id) {
        this.$refs.loginDialog.show();
      } else {
        window.open(this.store.url, "_blank");
      }
    },
    async addToFavs() {
      try {
        if (!this.user) {
          console.error("User data could not be loaded.");
          return;
        }

        const appID = this.app?.appID;
        if (!appID) {
          console.error("Invalid App ID.");
          return;
        }
        const favs = Array.isArray(this.user.favApps) ? this.user.favApps : [];
        if (favs.includes(appID)) {
          console.log("App is already in favorites.");
          return;
        }

        favs.push(appID);
        await this.updateUser({ favApps: favs });
        this.isInFavorites = true;

        console.log("App added to favorites successfully.");
      } catch (error) {
        console.error("Failed to add app to favorites:", error);
      }
    },
    async removeFromFavs() {
      try {
        if (!this.user) {
          console.error("User data could not be loaded.");
          return;
        }

        const appID = this.app?.appID;
        if (!appID) {
          console.error("Invalid App ID.");
          return;
        }
        let favs = Array.isArray(this.user.favApps) ? this.user.favApps : [];
        favs = favs.filter((id) => id !== appID);
        await this.updateUser({ favApps: favs });
        this.isInFavorites = false;

        console.log("App removed from favorites successfully.");
      } catch (error) {
        console.error("Failed to remove app from favorites:", error);
      }
    },
    redirectToLogin() {
      this.$router.push("/login.html");
    },
    async checkIfInFavorites() {
      if (this.user && this.user.favApps) {
        this.isInFavorites = this.user.favApps.includes(this.app?.appID);
      }
    },
    async loadData() {
      try {
        this.isLoading = true;
        await this.loadUser();
        const appID = this.$route.params.appId;
        const purchaseService = Services.getPurchaseService();
        this.app = await purchaseService.findAppById(appID);
        this.store = this.app?.store;
        ConstantsUtil.brands.forEach((brand) => {
          this.logos[brand.id] = brand.logo;
        });
        await this.checkIfInFavorites();
      } catch (err) {
        this.$route.push("/");
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    try {
      await this.loadData();
    } catch (err) {
      this.$route.push("/");
    }
  },
};
</script>
